@import "variables";
@import "reset";
@import "colors";
@import "typography";
@import "text-content";
@import "home";

html, body {
  background-color: $background-color;
  color: $text-color;
  @include mrsEavesRegular;
}

a {
  color: $text-color;
}

ul.links {
  list-style-type: none;

  li {
    display: block;
  }
}

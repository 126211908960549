$mobile-spacing: 25px;
$desktop-spacing: 40px;

@mixin padding($direction, $multiplier: 1) {
  padding-#{$direction}: $mobile-spacing * $multiplier;
  @media screen and (min-width: $lg-desktop-breakpoint) {
    padding-#{$direction}: $desktop-spacing * $multiplier;
  }
}

.home {
  max-width: $tablet-breakpoint;
  margin: 0 auto;
  margin-top: $mobile-spacing * 2;

  @media screen and (min-width: $desktop-breakpoint) {
    display: flex;
    max-width: 960px;
    margin: 100px auto;
  }

  .details {
    text-align: center;

    @media screen and (min-width: $desktop-breakpoint) {
      flex-basis: 33%;
      text-align: right;
      @include padding(top, 2.5);
      @include padding(right);
      @include padding(left);
    }

    .subtitle {
      @include mrsEavesRegular();
      font-size: 1.4rem;
    }

    .links {
      margin: ($mobile-spacing * 2) 0;

      @media screen and (min-width: $desktop-breakpoint) {
        margin-top: 2rem;
      }
    }
  }

  .photos {
    @media screen and (min-width: $desktop-breakpoint) {
      flex-basis: 66%;
      @include padding(left);
      @include padding(right);
    }

    img.gallery-image {
      width: 100%;
      height: auto;
      margin-bottom: 1rem;

      @media screen and (min-width: $desktop-breakpoint) {
        min-width: 400px;
        margin-bottom: 2rem;
      }
    }
  }
}

@mixin mrsEavesRegular {
  font-family: mrs-eaves-xl-serif-narrow, serif;
  font-weight: 400;
  font-style: normal;
}

@mixin mrsEavesBold {
  font-family: mrs-eaves-xl-serif-narrow, serif;
  font-weight: 700;
  font-style: normal;
}

html, body {
  font-size: 18px;

  @media screen and (min-width: $desktop-breakpoint) {
    font-size: 16px;
  }
}

h1, h2, h3, h4, h5, h6 {
  @include mrsEavesBold();
  margin: 1rem 0;

  & + .subtitle {
    margin-top: -0.5rem;
  }
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.6rem;
}

h3 {
  font-size: 1.4rem;
}

h4 {
  font-size: 1.2rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.8rem;
}

p {
  line-height: 1.3rem;
  margin-top: 0;
  margin-bottom: 1rem;
}

li {
  margin-bottom: 0.3rem;
}

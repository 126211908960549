.text-content {
  margin: 0 auto;
  max-width: 500px;

  .back-to-portfolio {
    display: block;
    border-bottom: 1px solid #4a4a4a;
    padding-bottom: 0.5rem;
    text-align: right;

    &.back-to-portfolio-bottom {
      border-bottom: 0;
      padding-bottom: 0;
      padding-top: 0.5rem;
      border-top: 1px solid #4a4a4a;
      margin-bottom: 1rem;
    }
  }

  ul {
    margin-left: 15px;

    li {
      margin-bottom: 0.8rem;
    }
  }
}

